<div
  class="cosling-container flex justify-content-center align-items-center pb-2"
>
  <div class="login-form flex flex-column justify-content-center">
    <!--   Login btn   -->
    <button
      id="login-button"
      (click)="keycloakLogin()"
      class="btn mt-3 btn-lg btn-dark-primary"
    >
      {{
        (isAuthenticated
          ? "authentification.login.button-open"
          : "authentification.login.button-connexion"
        ) | translate
      }}
    </button>
    <button
      *ngIf="isAuthenticated"
      id="logout-button"
      (click)="keycloakLogout()"
      class="btn mt-3 btn-lg btn-dark-primary"
    >
      {{
        "authentification.login.button-logout"
          | translate : { element: userProfile ? userProfile.email : "" }
      }}
    </button>
  </div>
</div>
