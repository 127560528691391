import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from '@angular/material/dialog';
import {
  MatTooltipDefaultOptions,
  MAT_TOOLTIP_DEFAULT_OPTIONS,
} from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Internal
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { InterceptorService } from './services/interceptor.service';
import { LangService } from './services/lang.service';
import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './views/authentification/login/login.component';
import { NavigationComponent } from './views/navigation/navigation.component';
import { ReLoginModalComponent } from './views/authentification/re-login-modal/re-login-modal.component';
import { RouteReuseStrategy } from '@angular/router';
import { AppRoutingStrategy } from './app.routing-strategy';
import { EventService } from './services/events.service';
import { BreadCrumbService } from './services/bread-crumb.service';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { environment } from '../environments/environment';

/** Custom options the configure the tooltip's default show/hide delays. */
export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 0,
  touchendHideDelay: 0,
};

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloak_url,
        realm: environment.keycloak_realm_id,
        clientId: environment.keycloak_client_id,
      },
      initOptions: {
        onLoad: 'check-sso',
      },
    });
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavigationComponent,
    ReLoginModalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgSelectModule,
    KeycloakAngularModule,
  ],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { autoFocus: false, hasBackdrop: true },
    },
    { provide: RouteReuseStrategy, useClass: AppRoutingStrategy },
    AuthService,
    EventService,
    LangService,
    ToastrService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService, // the order of declaration is crucial here: https://angular.io/guide/http#interceptor-order
      multi: true,
    },
    BreadCrumbService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// AOT compilation support.
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
