<div
  class="beforeLogin"
  *ngIf="!isLogIn() || onPage('/login'); else afterLogin"
>
  <nav class="navbar navbar-expand-lg navbar-dark py-1">
    <!--    the menu when small screen-->
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContentBefore"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContentBefore">
      <ul class="navbar-nav ml-auto">
        <div class="flex center" (click)="$event.stopPropagation()">
          <ng-select
            class="lang-selector pack"
            [clearable]="false"
            [(ngModel)]="selectedLang"
            (change)="switchLang()"
          >
            <ng-option *ngFor="let lang of languageList" value="{{ lang.id }}">
              <img src="assets/image/flag-{{ lang.id }}.png" alt="{{ lang }}" />
            </ng-option>
          </ng-select>
        </div>

        <li class="nav-item" *ngIf="!isAuthenticated">
          <a
            class="nav-link"
            [ngClass]="{ active: onPage('/login') }"
            [routerLink]="['/login']"
            >{{ "navigation.login" | translate
            }}<span class="sr-only">(current)</span></a
          >
        </li>
        <li class="nav-item" *ngIf="isAuthenticated">
          <a
            class="nav-link"
            [ngClass]="{ active: onPage('/login') }"
            (click)="authService.logOut()"
            >{{ "navigation.logout" | translate }}</a
          >
        </li>
      </ul>
    </div>
  </nav>
</div>

<ng-template class="afterLogin" #afterLogin>
  <nav class="navbar navbar-expand-lg navbar-dark py-1">
    <!--    the menu when small screen-->
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContentAfter"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse">
      <ul class="navbar-nav mr-auto"></ul>
      <div class="ctn">
        <ul id="breadcrumb">
          <li
            [routerLink]="['/editor/edition']"
            data-id="breadcrumb-home-button"
          >
            <a (click)="goHome()">{{ "navigation.edition" | translate }}</a>
          </li>
          <li
            [routerLink]="['/editor/edition']"
            *ngIf="currentProjectName !== ''"
            data-id="breadcrumb-project-button"
          >
            <a (click)="goProjectRoot()">
              <app-icon
                ref="folder-open"
                class="breadCrumb-icon"
                [disableAutoColor]="true"
                *ngIf="currentProjectName !== ''"
              ></app-icon>
              {{ currentProjectName }}
            </a>
          </li>
          <li
            *ngIf="currentFileName !== ''"
            data-id="breadcrumb-file-button"
            [routerLink]="['/editor/edition', currentFileId]"
          >
            <a (click)="goCurrentFile()">
              <app-icon
                ref="{{ currentFileType }}"
                class="breadCrumb-icon"
                [disableAutoColor]="true"
              ></app-icon>
              {{ currentFileName }}
            </a>
          </li>
          <li
            *ngIf="router.url.split('?')[0] === '/editor/configuration'"
            data-id="breadcrumb-run-button"
          >
            <a (click)="document.location.reload()">
              <app-icon
                ref="run"
                class="breadCrumb-icon"
                [disableAutoColor]="true"
                *ngIf="currentProjectName !== ''"
              ></app-icon>
              {{ "navigation.run" | translate }}
            </a>
          </li>
        </ul>
      </div>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown navbar-right">
          <a
            class="nav-link active"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            [ngClass]="{ active: onPage('/setting') }"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="user">
              {{ getUserName() }}
              <app-icon ref="user" class="ml-2"></app-icon>
            </span>
          </a>

          <div
            class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdown"
          >
            <div class="flex center" (click)="$event.stopPropagation()">
              <ng-select
                class="lang-selector pack"
                [clearable]="false"
                [(ngModel)]="selectedLang"
                (change)="switchLang()"
              >
                <ng-option
                  *ngFor="let lang of languageList"
                  value="{{ lang.id }}"
                >
                  <img
                    src="assets/image/flag-{{ lang.id }}.png"
                    alt="{{ lang }}"
                  />
                  <span class="ml-2">{{ lang.label }}</span>
                </ng-option>
              </ng-select>
            </div>
            <div class="dropdown-divider"></div>
            <div
              class="flex center mx-2 mouse-pointer"
              (click)="authService.logOut()"
            >
              <app-icon ref="logout" class="mr-2"></app-icon>
              <a class="dropdown-item">{{ "navigation.logout" | translate }}</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</ng-template>
<app-alert #alert></app-alert>
